import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shared-warranty-terms',
  templateUrl: './warranty-terms.component.html',
  styleUrls: ['./warranty-terms.component.scss'],
})
export class WarrantyTermsComponent {
  @Input({ required: true }) period: number;

  constructor(private translateService: TranslateService) {}

  public warrantyText(): string {
    if (this.period) {
      return this.translateService.instant('SERVICEPOINT.WARRANTY.WARRANTY_TERM_ONE_MULTIPLE', { period: this.period });
    }
    return this.translateService.instant('SERVICEPOINT.WARRANTY.WARRANTY_TERM_ONE');
  }
}
